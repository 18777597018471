// theme
import palette from '../theme/palette';

// ----------------------------------------------------------------------

export const colorPresets = [
  // DEFAULT
  {
    name: 'default',
    ...palette.light.primary,
  },
  // PURPLE
  {
    name: 'purple',
    lighter: '#EBD6FD',
    light: '#B985F4',
    main: '#7635dc',
    dark: '#431A9E',
    darker: '#200A69',
    contrastText: '#fff',
  },
  // CYAN
  {
    name: 'cyan',
    lighter: '#D1FFFC',
    light: '#76F2FF',
    main: '#1CCAFF',
    dark: '#0E77B7',
    darker: '#053D7A',
    contrastText: palette.light.grey[800],
  },
  // BLUE
  {
    name: 'blue',
    lighter: '#D1E9FC',
    light: '#76B0F1',
    main: '#2065D1',
    dark: '#103996',
    darker: '#061B64',
    contrastText: '#fff',
  },
  // ORANGE
  {
    name: 'orange',
    lighter: '#FEF4D4',
    light: '#FED680',
    main: '#fda92d',
    dark: '#B66816',
    darker: '#793908',
    contrastText: palette.light.grey[800],
  },
  // RED
  {
    name: 'red',
    lighter: '#FFE3D5',
    light: '#FFC1AC',
    main: '#FF3030',
    dark: '#B71833',
    darker: '#7A0930',
    contrastText: '#fff',
  },
  // design by adex
  {
    name: 'adex_green',
    lighter: '#5cd3e0',
    light: '#33b3cc',
    main: '#02a9c8',
    dark: '#00778f',
    darker: '#004a55',
    contrastText: '#fff',
  },
  {
    name: 'yellow',
    lighter: '#FFF8DC',
    light: '#FFECB3',
    main: '#FDA92D', // Your specified main color
    dark: '#B8860B',
    darker: '#8B5A00',
    contrastText: '#000',
  },
  {
    name: 'green',
    lighter: '#A1FFC3',
    light: '#5DFD8B',
    main: '#00ab55', // Your specified main color
    dark: '#007C3F',
    darker: '#004A28',
    contrastText: '#FFF',
  }
  
];

export const defaultPreset = colorPresets[0];
export const purplePreset = colorPresets[1];
export const cyanPreset = colorPresets[2];
export const bluePreset = colorPresets[3];
export const orangePreset = colorPresets[4];
export const redPreset = colorPresets[5];
export const adexgreenPreset = colorPresets[6];
export const yellowPreset = colorPresets[7];
export const greenPreset = colorPresets[8];

export default function getColorPresets(presetsKey) {
  return {
    purple: purplePreset,
    cyan: cyanPreset,
    blue: bluePreset,
    orange: orangePreset,
    red: redPreset,
    default: defaultPreset,
    adex_green: adexgreenPreset,
    yellow: yellowPreset,
    green: greenPreset
  }[presetsKey];
}
