import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
          <path d="M1940 3610 l0 -90 50 0 50 0 0 40 0 41 368 -3 367 -3 54 -34 c49 -32
111 -107 111 -135 0 -8 -190 -10 -620 -8 l-620 2 0 -415 0 -415 120 0 120 0 0
-146 0 -145 413 3 c411 3 412 3 467 27 30 14 75 40 99 60 53 42 114 134 129
194 9 35 20 49 59 74 132 86 208 244 189 396 -15 115 -119 271 -205 307 -22 9
-32 22 -40 53 -28 111 -104 203 -211 255 l-55 27 -422 3 -423 3 0 -91z m0
-556 l0 -254 50 0 50 0 0 75 0 75 350 0 c382 0 394 -2 471 -61 38 -30 83 -105
92 -155 5 -27 3 -33 -16 -38 -12 -3 -273 -6 -579 -6 l-558 0 0 313 0 312 70
-3 70 -4 0 -254z m1015 223 c-18 -89 -76 -160 -164 -201 -44 -20 -64 -21 -398
-24 l-353 -3 0 133 0 133 461 -3 461 -2 -7 -33z m152 -59 c52 -55 79 -109 88
-178 12 -97 -33 -216 -103 -271 -33 -26 -38 -24 -46 12 -9 47 -75 147 -123
186 l-43 35 55 52 c53 51 115 150 115 187 0 28 11 24 57 -23z m-167 -642 c0
-23 -62 -98 -105 -128 -63 -43 -111 -48 -465 -48 l-330 0 0 95 0 95 450 0
c382 0 450 -2 450 -14z"/>
<path d="M1100 1810 l0 -160 69 0 c89 0 117 11 138 54 13 29 14 39 3 65 -6 17
-21 33 -31 36 -23 7 -23 14 -4 39 16 21 20 64 7 82 -21 30 -59 44 -119 44
l-63 0 0 -160z m114 94 c23 -22 20 -41 -9 -60 -31 -20 -45 -11 -45 30 0 49 23
61 54 30z m26 -139 c18 -21 8 -48 -21 -59 -41 -14 -59 -2 -59 39 0 33 2 35 34
35 18 0 39 -7 46 -15z"/>
<path d="M1490 1810 l0 -160 90 0 90 0 0 30 c0 30 -1 30 -55 30 l-55 0 0 35 0
35 55 0 c54 0 55 0 55 30 0 30 -1 30 -55 30 l-55 0 0 35 0 35 55 0 c54 0 55 0
55 30 l0 30 -90 0 -90 0 0 -160z"/>
<path d="M1943 1963 c-34 -7 -73 -52 -73 -85 0 -40 33 -67 111 -92 92 -30 95
-75 7 -76 -29 0 -41 6 -54 25 -11 17 -25 25 -45 25 -71 0 -4 -94 76 -105 89
-14 162 37 151 105 -7 43 -23 55 -109 81 -60 18 -68 23 -65 42 2 18 11 23 45
25 34 3 44 -1 53 -17 11 -21 54 -29 64 -13 10 16 -20 57 -54 74 -34 17 -61 20
-107 11z"/>
<path d="M2280 1940 c0 -29 2 -30 45 -30 l45 0 0 -130 0 -130 35 0 35 0 0 130
0 130 45 0 c43 0 45 1 45 30 l0 30 -125 0 -125 0 0 -30z"/>
<path d="M2677 1963 c-4 -3 -7 -75 -7 -160 l0 -153 84 0 c88 0 102 6 91 40 -5
17 -15 20 -61 20 l-54 0 0 130 c0 130 0 130 -23 130 -13 0 -27 -3 -30 -7z"/>
<path d="M3150 1963 c-35 -7 -75 -38 -100 -78 -88 -142 101 -299 234 -194 58
46 71 146 27 208 -35 48 -102 75 -161 64z m83 -74 c46 -21 59 -80 30 -129 -45
-76 -173 -39 -173 51 0 70 73 110 143 78z"/>
<path d="M3630 1943 c-34 -92 -94 -239 -102 -248 -17 -19 -9 -45 16 -45 17 0
30 10 45 35 20 34 23 35 79 35 57 0 59 -1 75 -35 13 -26 23 -35 42 -35 14 0
25 4 25 9 0 5 -26 76 -59 157 -53 135 -61 149 -85 152 -21 3 -28 -2 -36 -25z
m54 -96 c32 -78 32 -77 -18 -77 l-45 0 15 43 c23 66 23 67 29 67 2 0 11 -15
19 -33z"/>
<path d="M4000 1811 l0 -161 48 0 c89 1 152 36 178 99 28 68 8 141 -52 187
-24 19 -49 26 -103 31 l-71 6 0 -162z m125 89 c38 -14 59 -70 45 -119 -12 -47
-27 -60 -74 -68 l-36 -6 0 102 c0 111 3 115 65 91z"/>
<path d="M3696 1587 c-22 -16 -18 -55 6 -71 14 -8 21 -7 33 7 8 9 10 12 3 7
-19 -16 -36 -12 -43 11 -10 30 18 57 39 39 9 -7 16 -8 16 -2 0 5 -7 13 -16 16
-20 8 -17 8 -38 -7z"/>
<path d="M3782 1588 c-30 -30 -2 -86 38 -73 19 6 25 22 20 55 -4 28 -37 39
-58 18z m43 -34 c0 -22 -5 -29 -20 -29 -24 0 -34 43 -13 56 21 14 33 4 33 -27z"/>
<path d="M3908 1593 c6 -2 13 -23 13 -46 l2 -42 6 40 c6 38 -2 55 -24 54 -5 0
-4 -3 3 -6z"/>
<path d="M3955 1590 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M4061 1555 l1 -50 8 40 c5 22 4 44 -1 50 -5 5 -9 -11 -8 -40z"/>
<path d="M4103 1588 c9 -7 18 -29 20 -48 4 -34 4 -34 5 6 2 30 -3 44 -14 48
-25 10 -29 7 -11 -6z"/>
<path d="M3870 1550 c0 -22 2 -40 4 -40 2 0 6 18 8 40 2 22 0 40 -4 40 -5 0
-8 -18 -8 -40z"/>
<path d="M4155 1570 c-8 -25 11 -60 33 -59 14 0 14 2 2 6 -23 9 -31 25 -25 51
7 27 -1 29 -10 2z"/>
<path d="M4213 1551 c0 -48 -10 -64 -34 -58 -11 3 -17 1 -13 -4 11 -19 45 -9
54 17 12 31 13 84 1 84 -4 0 -8 -17 -8 -39z"/>
<path d="M3975 1545 c0 -14 3 -25 8 -25 4 0 7 11 7 25 0 14 -3 25 -7 25 -5 0
-8 -11 -8 -25z"/>


        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
